import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import joi from 'joi';
import styles from './index.module.css';
import Button from 'components/core/button';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { QuestStepEvents } from 'api/quests';
import { useIntlValidation } from 'hooks/intl/validation';
import { joiResolver } from '@hookform/resolvers/joi';
import { FaPlus } from 'react-icons/fa6';
import { Event } from './Event';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { transformFromBackendTimer } from 'utils/dates';

type Props = {
  isEdit: boolean;
  events: QuestStepEvents[];
  isUniversal: boolean;
  onSave: (events: QuestStepEvents[]) => void;
  onBack: () => void;
  timer?: number;
};

export type EventForm = { eventId?: string } & Omit<QuestStepEvents, 'events'>;
export type EventsForm = {
  events?: EventForm[];
};

export const EditEvents: FC<Props> = observer(
  ({ isEdit, events, onBack, onSave, isUniversal, timer }) => {
    const { layoutStore } = useBaseStores();
    const intl = useIntl();

    useEffect(() => {
      layoutStore.setSidebarTitle(
        intl.formatMessage({
          id: 'sidebar.quest.events',
          defaultMessage: 'Events',
        })
      );

      return () => {
        layoutStore.setSidebarTitle(
          intl.formatMessage({
            id: 'sidebar.quest',
            defaultMessage: isEdit ? 'Edit quest info' : 'Create a quest',
          })
        );
      };
    }, [layoutStore, isEdit, intl]);

    const { validationOptions } = useIntlValidation();

    const formScheme = useMemo(
      () =>
        joi.object<EventsForm>({
          events: joi.array().items(
            joi.object({
              id: joi.string().optional(),
              name: joi.string().empty('').required(),
              eventId: joi.string().empty('').required(),
              events: joi.array(),
              timer: joi.number().empty(''),
              exclusive: joi.boolean(),
              position: joi.number(),
              reward: joi
                .number()
                .min(0)
                .max(Number.MAX_SAFE_INTEGER)
                .empty(undefined)
                .empty(''),
            })
          ),
        }),
      []
    );

    const form = useForm<EventsForm>({
      resolver: joiResolver(formScheme, validationOptions),
      mode: 'onChange',
      defaultValues: !!events.length
        ? {
            events:
              events.map(
                ({
                  id,
                  name,
                  events,
                  timer: t,
                  position,
                  exclusive,
                  reward,
                }) => ({
                  id,
                  //@ts-ignore
                  eventId: events?.[0]?.event_id,
                  name,
                  timer: t || timer,
                  position,
                  exclusive,
                  reward,
                  events: undefined,
                })
              ) || [],
          }
        : {
            events: [
              {
                name: 'Event_name',
                exclusive: false,
                timer: isUniversal ? timer : undefined,
                reward: undefined,
              },
            ],
          },
    });
    const { control, handleSubmit } = form;

    const { move, fields, remove, append } = useFieldArray<EventsForm>({
      name: 'events',
      control,
    });

    const onSubmit = ({ events }: EventsForm) => {
      onSave(
        events?.map(({ eventId, id, ...event }, index) => ({
          ...event,
          id: id,
          events: [{ event_id: eventId }],
          position: index,
        })) || []
      );
    };

    const onAddEvent = () => {
      append({
        id: undefined,
        name: '',
        reward: 0,
        exclusive: false,
        position: 1,
        timer: isUniversal ? timer : undefined,
      });
    };

    const moveCard = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        move(dragIndex, hoverIndex);
      },
      [move]
    );

    return (
      <FormProvider {...form}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.wrapper}>
              <h4>Events</h4>
              <span className={styles.description}>In order of execution</span>
            </div>
            <Button
              className={styles.button}
              size='medium'
              variant='primary'
              onClick={onAddEvent}
            >
              {intl.formatMessage({
                id: 'sidebar.create_event.add_event',
                defaultMessage: 'Add event',
              })}
              <FaPlus />
            </Button>
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className={styles.list}>
              {fields.map((event, i) => (
                <Event
                  isUniversal={isUniversal}
                  key={event.id}
                  index={i}
                  id={event.id}
                  event={event as EventForm}
                  moveCard={moveCard}
                  onDelete={() => remove(i)}
                  timer={timer}
                />
              ))}
            </div>
          </DndProvider>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.apply_btn}
            variant='secondary'
            onClick={onBack}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.back',
              defaultMessage: 'Back',
            })}
          </Button>
          <Button
            className={styles.apply_btn}
            variant='primary'
            onClick={handleSubmit(onSubmit, (error) => console.log(error))}
          >
            {intl.formatMessage({
              id: 'sidebar.quest_create.save',
              defaultMessage: 'Save changes',
            })}
          </Button>
        </div>
      </FormProvider>
    );
  }
);
