import React from 'react';
import { useQuestDetailsPageStore } from '../provider/StoreProvider';
import { observer } from 'mobx-react-lite';
import styles from './index.module.css';
import { formattedDate, FULL_DATE } from 'utils/dates';
import { ReactComponent as QuestsIcon } from 'assets/images/icons/quests.svg';
import { useIntl } from 'react-intl';
import { InfoBox } from './info-box';
import { BadgeStatus } from 'entities/quest';
import { cutId } from 'utils/strings';
import CopyButton from 'components/core/copy-button';
import classNames from 'classnames';

export const QuestInfo = observer(() => {
  const intl = useIntl();
  const store = useQuestDetailsPageStore();

  const quest = store.quest!;
  const perc =
    100 -
    (((quest.budget || 0) - (quest.budgetBalance || 0)) * 100) /
      (quest.budget || 1);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className='flex gap-3 items-center'>
          <div className='flex items-center justify-center bg-primary-10 rounded-full w-14 h-14'>
            <QuestsIcon color='#FF7435' />
          </div>
          <div className='flex flex-col gap-1'>
            <h2 className='m-0'>{quest.name}</h2>
            <span className='text-text-60 font-semibold'>
              {quest.templateUrl}
            </span>
          </div>
        </div>
        <div className='flex gap-7 items-center'>
          {quest.questId && (
            <div className='flex flex-col'>
              <span className='text-base-40 text-sm'>Key</span>
              <span className='font-semibold flex gap-1'>
                <span>{cutId(quest.questId)}</span>
                <CopyButton text={quest.questId} />
              </span>
            </div>
          )}
          <div className='flex flex-col'>
            <span className='text-base-40 text-sm'>Start</span>
            <span className='font-semibold'>
              {formattedDate(quest.startedAt!, FULL_DATE)}
            </span>
          </div>
          <div className='flex flex-col'>
            <span className='text-base-40 text-sm'>Finish</span>
            <span className='font-semibold'>
              {formattedDate(quest.expiredAt!, FULL_DATE)}
            </span>
          </div>
          <BadgeStatus status={quest.status!} />
        </div>
      </div>
      <div className={styles.cards}>
        <div className={styles.card}>
          <h4>
            {intl.formatMessage({
              id: 'page.quests.budget',
              defaultMessage: 'Budget',
            })}
          </h4>
          <div
            className={classNames('grid gap-2', {
              'grid-cols-[130px_130px_130px_130px_1fr]': !!quest.budget,
              'grid-cols-[130px_130px_130px_1fr]': !quest.budget,
            })}
          >
            {!!quest.budget && (
              <InfoBox
                title={intl.formatMessage({
                  id: 'page.quests.budget',
                  defaultMessage: 'Budget',
                })}
                value={`$${quest.budget}`}
              />
            )}
            <InfoBox
              title={intl.formatMessage({
                id: 'page.quests.cpi',
                defaultMessage: 'CPI',
              })}
              value={`$${quest.cpi || 0}`}
            />
            <InfoBox
              title={intl.formatMessage({
                id: 'page.quests.installations',
                defaultMessage: 'Installations',
              })}
              value={`$${quest.enrolledUsers || 0}`}
            />
            <InfoBox
              title={intl.formatMessage({
                id: 'page.quests.total_revenue',
                defaultMessage: 'Total revenue',
              })}
              value={`$${quest.totalRevenue || 0}`}
            />
            <div className='flex flex-col gap-3 ml-2'>
              <div className='flex justify-between items-center'>
                <h4>
                  {intl.formatMessage({
                    id: 'page.quests.quest_budget',
                    defaultMessage: 'Quest budget',
                  })}
                </h4>
                <div>
                  <span className='font-semibold'>
                    ${(quest.budget || 0) - (quest.budgetBalance || 0)}{' '}
                  </span>
                  <span className='text-text-40 font-semibold'>
                    / ${quest.budget || 0}
                  </span>
                </div>
              </div>
              <div className={styles.progress_container}>
                <div
                  className={styles.progress}
                  style={{ width: `${perc}%` }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.card}>
          <h4>
            {intl.formatMessage({
              id: 'page.quests.statistics',
              defaultMessage: 'Statistics',
            })}
          </h4>
          <div className='grid gap-2 grid-cols-6'>
            <InfoBox
              className='col-span-2'
              title={intl.formatMessage({
                id: 'page.quests.not_activated',
                defaultMessage: 'Not activated',
              })}
              value={`${quest.reward}`}
            />
            <InfoBox
              className='col-span-2'
              title={intl.formatMessage({
                id: 'page.quests.activated',
                defaultMessage: 'Activated',
              })}
              value={`${quest.reward}`}
            />
            <InfoBox
              className='col-span-2'
              title={intl.formatMessage({
                id: 'page.quests.quest_completed',
                defaultMessage: 'Quest completed',
              })}
              value={`%${quest.reward}`}
            />
            <InfoBox
              className='col-span-3'
              title={intl.formatMessage({
                id: 'page.quests.award_received',
                defaultMessage: 'Average event completion time',
              })}
              value={`6h`}
            />
            <InfoBox
              className='col-span-3'
              title={intl.formatMessage({
                id: 'page.quests.award_received',
                defaultMessage: 'Average quest completion time',
              })}
              value={`4h`}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
});
