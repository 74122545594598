import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as DragIcon } from 'assets/images/icons/drag.svg';
import styles from './index.module.css';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';
import { QuestStepEvents } from 'api/quests';
import { transformFromBackendTimer } from 'utils/dates';

export type EventProps = {
  index: number;
  id: string;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDelete: () => void;
  event: QuestStepEvents;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

export const Event: FC<EventProps> = ({
  index,
  moveCard,
  onDelete,
  event,
  id,
}) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'event',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'event',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  preview(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={styles.container}
    >
      <div className={styles.wrapper}>
        <button ref={drag}>
          <DragIcon />
        </button>
        <div className={styles.content}>
          <div className={styles.col}>
            <span className={styles.name}>{event.name}</span>
            {event.exclusive && (
              <span className={styles.exclusive}>
                {intl.formatMessage({
                  id: 'sidebar.create_quest.exclusive_event',
                  defaultMessage: 'Exclusive event',
                })}
              </span>
            )}
          </div>
          <div className={styles.row}>
            <span className={styles.label}>
              {intl.formatMessage({
                id: 'sidebar.create_quest.event_timer',
                defaultMessage: 'Event timer',
              })}
            </span>
            <span className={styles.value}>
              {transformFromBackendTimer(event.timer || 0).ui}
            </span>
          </div>
          <div className={styles.row}>
            <span className={styles.label}>
              {intl.formatMessage({
                id: 'sidebar.create_quest.reward',
                defaultMessage: 'reward',
              })}
            </span>
            <span className={styles.value}>${event.reward || 0}</span>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button size='small' variant='secondary' onClick={onDelete}>
          {intl.formatMessage({
            id: 'sidebar.create_event.delete',
            defaultMessage: 'Delete',
          })}
        </Button>
      </div>
    </div>
  );
};
