/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import { QuestsApi } from 'api';
import { URL_SEARCH_PARAMS } from 'assets/config';
import { makeAutoObservable } from 'mobx';
import { FilterModel } from 'models/filter/FilterModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { readStrFromQueryParams } from 'utils/queryParams';
import { NotificationStore } from 'stores/NotificationStore';
import { AdminQuestsModel } from 'models/AdminQuestModel';
import { CreateQuest, QuestStatus } from 'api/quests';
import { AdminQuestModel } from 'models/request/AdminQuestModel';

export class QuestsPageStore {
  constructor(
    private questsApi: QuestsApi,
    private notificationStore: NotificationStore
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _quests = new CollectionWithPages<AdminQuestsModel, AdminQuestModel>({
    pageQueryParamId: URL_SEARCH_PARAMS.page,
    fetchFn: async (params) => {
      const result = await this.questsApi.adminQuest.getQuestsByPage1(
        {
          ...params,
        },
        {
          paramsSerializer: {
            indexes: null,
          },
        }
      );

      const items = result.data.data.map((x) => new AdminQuestsModel(x));

      return {
        items: items,
        totalItems: result.data.totalItems,
        totalPages: result.data.totalPages,
      };
    },
    getFilterFromQueryParamsFn: (urlParams) => {
      const filters: FilterModel<AdminQuestModel>[] = [];

      const searchStr = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.name
      );

      const status = readStrFromQueryParams(
        urlParams,
        URL_SEARCH_PARAMS.status
      );

      if (searchStr != null) {
        filters.push({
          id: URL_SEARCH_PARAMS.name,
          value: {
            name: searchStr,
          },
        });
      }

      if (status) {
        filters.push({
          id: URL_SEARCH_PARAMS.status,
          value: {
            status: status.split(',') as QuestStatus[],
          },
        });
      }

      return filters;
    },
    filterCounterIgnoreValues: [URL_SEARCH_PARAMS.name],
    itemsPerPage: 12,
  });

  get quests() {
    return this._quests;
  }

  async createQuest(quest: CreateQuest) {
    try {
      await this.questsApi.adminQuest.createQuest(quest);
      this.notificationStore.notify({
        title: 'Quest was created',
        type: 'success',
      });
      this._quests.refresh();
    } catch (error) {
      this.notificationStore.handleApiError(error);
      console.error(error);
    }
  }

  async init(props: { filter: FilterModel<AdminQuestModel>[]; page: number }) {
    const { filter, page } = props;

    this._quests.fetchData(filter, page);
  }
}
