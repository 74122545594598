/*
 * Copyright (C) 2024 Finharbor DOO. - All Rights Reserved
 *
 * Unauthorized copying or redistribution of this file in source and binary forms via any medium
 * is strictly prohibited.
 */

import styles from './index.module.css';
import { Image, Upload, UploadFile } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactComponent as FileSvg } from 'assets/images/icons/file.svg';
import { ReactComponent as UploadSvg } from 'assets/images/icons/upload.svg';
import { useIntl } from 'react-intl';
import { CreateQuestForm } from '..';
import { TiEye } from 'react-icons/ti';
import classNames from 'classnames';
import { MdEdit } from 'react-icons/md';
import { MdSaveAlt } from 'react-icons/md';
import { FileType, getBase64 } from 'utils/image';
import { memo, useCallback, useState } from 'react';

type BannerProps = {
  image?: string;
};

export const Banner = memo(({ image }: BannerProps) => {
  const intl = useIntl();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const { control } = useFormContext<CreateQuestForm>();

  const handlePreview = useCallback(async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  }, []);

  return (
    <div className={styles.col}>
      <h5>
        {intl.formatMessage({
          id: 'sidebar.create_quest.banner',
          defaultMessage: 'Banner',
        })}
      </h5>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
      <Controller
        control={control}
        rules={{ required: true }}
        name='image'
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <ImageField
              handlePreview={handlePreview}
              value={value}
              onChange={onChange}
              image={image}
              error={error?.message}
            />
          );
        }}
      />
    </div>
  );
});

type ImageFieldProps = {
  value: UploadFile<any>;
  image?: string;
  error?: string;
  handlePreview: (file: UploadFile) => Promise<void>;
  onChange: (file?: UploadFile<any>) => void;
};

export const ImageField = memo(
  ({ value, onChange, image, error, handlePreview }: ImageFieldProps) => {
    return (
      <div className={styles.col}>
        <Upload
          onPreview={handlePreview}
          maxCount={1}
          listType='picture'
          className={classNames(styles['upload_wrapper'], {
            [styles.image_upload]: !!value || !!image,
          })}
          fileList={
            value
              ? [{ ...value, status: 'done' }]
              : image
              ? [
                  {
                    uid: '1',
                    name: 'Banner',
                    status: 'done',
                    url: image,
                  },
                ]
              : undefined
          }
          customRequest={(data) => {}}
          itemRender={(_, file, list, actions) => (
            <div className={styles.image_item}>
              <img
                width={40}
                height={40}
                src={file.thumbUrl || file.url}
                alt={file.name}
                crossOrigin={file.crossOrigin}
              />
              <span className={styles.image_name}>{file.name}</span>
              <div className={styles.image_actions}>
                <div
                  className={styles.image_action_container}
                  onClick={actions.preview}
                  tabIndex={0}
                  role='button'
                >
                  <TiEye color='#B1B1BC' />
                </div>
                <a
                  download='link'
                  href={file.url || file.thumbUrl}
                  className={styles.image_action_container}
                  onClick={actions.download}
                  tabIndex={0}
                  role='button'
                >
                  <MdSaveAlt color='#B1B1BC' />
                </a>
              </div>
            </div>
          )}
          onChange={({ file, fileList }) => {
            if (!fileList.length) {
              onChange(undefined);
            } else {
              onChange(file);
            }
          }}
        >
          {value || image ? (
            <div className={styles.image_action_container}>
              <MdEdit color='#B1B1BC' />
            </div>
          ) : (
            <div className={styles.upload}>
              <div className={styles.upload_content}>
                <div className={styles.upload_content_file}>
                  <FileSvg width={44} height={44} color='#9B9BA8' />
                  <div className={styles.upload_file_svg}>
                    <UploadSvg color='#fff' />
                  </div>
                </div>
              </div>
              Click to upload or drag and drop your file
            </div>
          )}
        </Upload>
        {error && <span className={styles.error}>{error}</span>}
      </div>
    );
  }
);
