import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as DragIcon } from 'assets/images/icons/drag.svg';
import styles from './index.module.css';
import Button from 'components/core/button';
import { useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { EventForm, EventsForm } from '..';
import FormField from 'components/form/form-field';
import { PatternFormat } from 'react-number-format';
import InputField from 'components/core/input-field';
import FormLabel from 'components/form/form-label';
import FormListMultiSelect from 'components/form/form-list-multi-select';
import { observer } from 'mobx-react-lite';
import { useBaseStores } from 'providers/BaseStoresProvider';
import BigNumber from 'bignumber.js';

export type EventProps = {
  index: number;
  timer?: number;
  id: string;
  isUniversal: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onDelete: () => void;
  event: EventForm;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

export const Event: FC<EventProps> = observer(
  ({ index, moveCard, onDelete, event, timer, isUniversal, id }) => {
    const { currencyStore } = useBaseStores();
    const intl = useIntl();
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<
      DragItem,
      void,
      { handlerId: Identifier | null }
    >({
      accept: 'event',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) return;

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveCard(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: 'event',
      item: () => {
        return { id, index };
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const { control, setValue } = useFormContext<EventsForm>();

    const [isExclusive, reward] = useWatch({
      control,
      name: [`events.${index}.exclusive`, `events.${index}.reward`],
    });

    const opacity = isDragging ? 0 : 1;
    preview(drop(ref));

    return (
      <div
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
        className={styles.container}
      >
        <button ref={drag}>
          <DragIcon />
        </button>

        <div className={styles.content}>
          <div className={styles.two_cols}>
            <Controller
              control={control}
              name={`events.${index}.name`}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.event_name',
                    defaultMessage: 'Name',
                  })}
                  labelType='xs-secondary'
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_quest.event_name.placeholder',
                    defaultMessage: 'Event name',
                  })}
                  variant='primary'
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <Controller
              control={control}
              name={`events.${index}.eventId`}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormField
                  label={intl.formatMessage({
                    id: 'sidebar.create_quest.event_id',
                    defaultMessage: 'AppsFlyer event id',
                  })}
                  labelType='xs-secondary'
                  placeholder={intl.formatMessage({
                    id: 'sidebar.create_quest.event_id.placeholder',
                    defaultMessage: 'Event Id',
                  })}
                  variant='primary'
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
          </div>
          <div className={styles.two_cols}>
            <div className={styles.col}>
              <FormLabel
                type='xs-secondary'
                text={intl.formatMessage({
                  id: 'sidebar.create_quest.timer',
                  defaultMessage: 'Timer',
                })}
              />
              <Controller
                control={control}
                name={`events.${index}.timer`}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <PatternFormat
                    disabled={isUniversal && !isExclusive}
                    customInput={InputField}
                    format='##:##:##:##'
                    variant='primary'
                    allowEmptyFormatting
                    placeholder='hh:mm'
                    mask={['D', 'D', 'H', 'H', 'M', 'M', 'S', 'S']}
                    isFormatted
                    value={value || ''}
                    onValueChange={(e) => onChange(e.value)}
                    onBlur={() => {
                      const newValue = Array.from({ length: 8 })
                        .map((_, index) =>
                          value ? value.toString()[index] || '0' : '0'
                        )
                        .join('');

                      onChange(newValue);
                    }}
                    error={error?.message}
                  />
                )}
              />
            </div>
            <div className='grid grid-cols-[110px_110px] gap-2'>
              <Controller
                control={control}
                name={`events.${index}.reward`}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormField
                    label={intl.formatMessage({
                      id: 'sidebar.create_quest.reward',
                      defaultMessage: 'Reward',
                    })}
                    placeholder='$60'
                    variant='primary'
                    labelType='xs-secondary'
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />

              <FormField
                label={intl.formatMessage({
                  id: 'sidebar.create_quest.token',
                  defaultMessage: 'Token',
                })}
                disabled
                placeholder='60EG'
                variant='primary'
                labelType='xs-secondary'
                value={
                  reward
                    ? `${currencyStore
                        .exchangeCurrencies({
                          to: 'EGG',
                          amount: BigNumber(reward || 0),
                          from: 'USDT',
                        })
                        ?.targetAmount.toString()} EG` || ''
                    : ''
                }
              />
            </div>
          </div>

          <div className={styles.row}>
            <Controller
              control={control}
              name={`events.${index}.exclusive`}
              render={({ field: { value, onChange } }) => (
                <FormListMultiSelect
                  title={''}
                  options={[
                    {
                      id: 'exclusive',
                      label: 'This event is exclusive',
                      value: true,
                    },
                  ]}
                  selected={
                    value
                      ? [
                          {
                            id: 'exclusive',
                            label: 'IOS',
                            value: true,
                          },
                        ]
                      : undefined
                  }
                  onSelect={(v) => {
                    const value = v?.[0]?.value ?? false;
                    onChange(value);
                    if (!value && isUniversal) {
                      setValue(`events.${index}.timer`, timer);
                    }
                  }}
                />
              )}
            />
            <Button size='small' variant='secondary' onClick={onDelete}>
              {intl.formatMessage({
                id: 'sidebar.create_event.delete',
                defaultMessage: 'Delete',
              })}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
