import { TableProps } from 'antd';
import CopyButton from 'components/core/copy-button';
import Table from 'components/core/table';
import React from 'react';
import { useIntl } from 'react-intl';
import { cutId } from 'utils/strings';
import styles from './index.module.css';
import { UserQuestsModel } from 'models/UserQuestModel';
import { CollectionWithPages } from 'stores/collection/CollectionWithPages';
import { formattedDate, FULL_DATE } from 'utils/dates';
import { QuestStatus } from 'api/quests';
import { BadgeStatus } from 'entities/quest';

type UserProps = {
  collection: CollectionWithPages<
    UserQuestsModel,
    { page?: number; size?: number }
  >;
};

export const Users = ({ collection }: UserProps) => {
  const intl = useIntl();

  const columns: TableProps<UserQuestsModel>['columns'] = [
    {
      title: intl.formatMessage({
        id: 'table.quests.col.id',
        defaultMessage: 'ID',
      }),
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <div className='flex items-center gap-2'>
          <span>{cutId(id)}</span>
          <CopyButton text={id} />
        </div>
      ),
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.start',
        defaultMessage: 'Start',
      }),
      dataIndex: 'startedAt',
      key: 'startedAt',
      render: (startedAt) => <span>{formattedDate(startedAt, FULL_DATE)}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.quests.col.finish',
        defaultMessage: 'Finish',
      }),
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      render: (expiredAt) => <span>{formattedDate(expiredAt, FULL_DATE)}</span>,
      width: 20,
    },
    {
      title: intl.formatMessage({
        id: 'table.subscriptions.col.status',
        defaultMessage: 'Status',
      }),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <BadgeStatus status={status} />,
      width: 30,
    },
  ];

  return (
    <div className={styles.main}>
      <h3>
        {intl.formatMessage({
          id: 'page.quest.users',
          defaultMessage: 'Users',
        })}
      </h3>
      <Table<UserQuestsModel, { page?: number; size?: number }>
        collection={collection}
        columns={columns}
      />
    </div>
  );
};
